.DataVid__section {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    
}

.DataVid__section video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(1) contrast(1) saturate(1) hue-rotate(61.2deg);
}

.DataVid__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    padding: 40px;
    height: 85vh;
    position: absolute;
    border: 1px solid rgba(255,255,255,0.3);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: 100%;
    max-width: 80%;
    text-align: center;
    background: linear-gradient(198deg, #FFFFFF14 0%, #192A1FAB 52%); /* Semi-transparent background */
    color: white; /* Ensures text is readable */
    border-radius: 10px; /* Optional: Adds rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Optional: Adds a subtle shadow */
}

.DataVid__content h1 {
    color: #E1FFD9;
    line-height: 1em;
    letter-spacing: -0.04em;
    word-spacing: 0.03em;
    font-size: 80px;
    font-family: 'Space Grotesk', sans-serif;
}

.DataVid__content p {
    color: #F1FFDA9E;
    font-size: 16px;
    line-height: 1.5em;
}

.DataVid__video { 
    height: auto;
}

@media screen and ( max-width: 600px ) {
    .DataVid__section {
        height: 40vh;
    }
    .DataVid__content{
        height: 30vh;
        top: 45%;
        transform: translateY(-45%);
    }
    .DataVid__content h1{
    font-size: 3rem!important;   
    }
}

@media screen and ( max-width: 450px ) {
    .DataVid__section {
        height: 40vh;
    }
    .DataVid__content{
        height: 34vh;
        top: 50%;
        transform: translateY(-50%);
    }
    .DataVid__content h1{
        font-size: 2rem!important;   
    }
    
    .DataVid__section video { 
        height: 40vh;
    }
}