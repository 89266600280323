.offers__section ul {
    width: 90%;
}
.offers__section ul li {
    font-size: 18px;
    color: #001135;
    transition: all 0.3s ease-in-out;
}
.offers__section ul li:hover {
    color: #00588b;
    transition: all 0.3s ease-in-out;
}