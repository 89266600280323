.security_section {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    height: 90vh;
}

.security_section .overlay{
    position: absolute;
    width: 100%;
    height: 90vh;
    background-color: rgba(0, 43, 0, 0.5);
}

.security_section h1{
    color: #E1FFD9;
    font-size: 70px;
    line-height: 0.9em;
    letter-spacing: -0.02em;
}

.security_section .w_50{
    width: 50%;
}



@media (min-width: 1400px) {
    .security_section, .security_section .overlay{
        height: 50vh!important;
    }
}

@media (max-width: 1400px) {

    .security_section .w_50{
        width: 50%!important;
    }
    .security_section, .security_section .overlay {
        height: 90vh;
    }
}

@media (max-width: 600px) {
    .security_section h1 {
        font-size: 3rem!important;
        text-align: center;
    }
    .security_section .w_50{
        width: 90%!important;
    }
    .security_section {
        height: 60vh;
    }
    .security_section .overlay{
        height: 60vh;
    }
}

@media (max-width: 450px) {
    .security_section h1 {
        padding: 1rem 0;
    }
    .security_section .w_50{
        width: 90%!important;
    }
    .security_section {
        height: 85vh;
    }
    .security_section .overlay{
        height: 85vh;
    }
}