.progress-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 50px 0;
    width: 75%;
  }
  
  .progress {
    --progress: 0%;
    border-radius: 0!important;
    width: 100%;
    height: 5px!important;
    position: relative;

  }
  
  .progress .bar {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--progress);
    height: 5px;
    background: #000;
    background-repeat: repeat;
    transition: width 3s ease;
  }
  
  .progress .progress-value {
    height: 5px;
    text-align: right;
    padding-right: 5px;
    color: white;
    font-weight: bold;
  }
  
  @property --progress {
    syntax: "<length>";
    initial-value: 0%;
    inherits: true;
  }

  .pro__title {
    color: #192A1F;
    font-size: 70px;
    line-height: 0.9em;
    letter-spacing: -0.02em;
    font-family: 'Space Grotesk', sans-serif
  }

  .pro__desc {
    color: #003B1570;
    font-size: 16px;
    line-height: 1.4em;
  }

  .pro__align{
    padding: 0 3rem;
  }

  @media screen and ( max-width: 600px ) {
    .pro__align{
      padding: 0;
    }
    .pro__title {
      font-size: 3rem;
      text-align: center;
    }
    .progress-container {
      width: 100%;
    }
  }

  @media screen and ( max-width: 450px ) {
    .pro__desc {
      text-align: center;
    }
  }