.service_section {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    height: 200vh;
}

.service_section .overlay{
    position: absolute;
    width: 100%;
    height: 200vh;
    background-color: rgba(0, 31, 43, 0.5);
}

.service_section h1{
    color: #E1FFD9;
    font-size: 70px;
    line-height: 0.9em;
    letter-spacing: -0.02em;
}

.service_section p{
    color: #F1FFDA9E;
    line-height: 1.3em;
}
    
.service_section .w_50 { 
    width: 50%;
}

@media (min-width: 1600px) {
    .service_section, .service_section .overlay{
        height: 130vh!important;
    }
}


@media screen and ( max-width: 600px ){
    .service_section h1{
      font-size: 3rem!important;
      text-align: center;
    }

  
@media screen and ( max-width: 600px ){
    .service_section .w_50 { 
      width: 90%!important;
    }
    .service_section {
        height: 280vh;
    }
    
    .service_section .overlay{
        height: 280vh;
    }
  }
}

@media screen and ( max-width: 450px ){
    .service_section {
        height: 340vh;
    }
    
    .service_section .overlay{
        height: 340vh;
    }
}