.flow_section {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.flow_section .overlay{
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 43, 0, 0.5);
}

.flow_section h1{
    color: #E1FFD9;
    font-size: 70px;
    line-height: 0.9em;
    letter-spacing: -0.02em;
}


.flow_section .w_50{
    width: 50%;
}

.flowCardSection{
    gap: 2rem;
}

@media (min-width: 1400px) {
    .flow_section, .flow_section .overlay{
        height: 100vh!important;
    }
}

@media (max-width: 1400px) {
    .flow_section, .flow_section .overlay{
        height: 150vh!important;
    }
}

@media (max-width: 600px) {
    .flow_section h1{
        font-size: 3rem;
    }
    .flowCardSection{
        gap: 0;
    }
    .flow_section .w_50{
        width: 90%;
    }
}

@media (max-width: 450px) {
    .flow_section, .flow_section .overlay{
        height: 300vh!important;
    }
    .flow_section h1{
        font-size: 3rem;
    }
    .flowCardSection{
        gap: 0;
    }
    .flow_section .w_50{
        width: 90%;
    }
}