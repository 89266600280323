.career-container {
  max-width: 900px;
  margin: 40px auto;
  padding: 40px;
  text-align: center;
}

.career-container h1 {
  color: #0a0f4f;
  margin-bottom: 30px;
  font-size: 3.5rem;
  font-weight: 600;
}

.redirect-btn {
  padding: 16px 32px;
  font-size: 1.1rem;
  font-weight: 500;
  color: #fff;
  background-color: #4361ee;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.redirect-btn:hover {
  background-color: #3046c9;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(67, 97, 238, 0.2);
}

.redirect-btn:active {
  transform: translateY(0);
}

.no-scroll {
  overflow: hidden;
  height: 100%;
}

.career-container p{
  width: 75%;
}

@media screen and (max-width: 600px) {
  .career-container h1{
    font-size: 2.5rem;
    line-height: 1.2;
  }
  .career-container p{
    width: 90%;
  }
}
