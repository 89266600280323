.companies__section .bg__map {
    height: 30rem;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.companies__section .overlay {
    background: rgba(255,255,255,0.85);
    width: 100%;
    height: 30rem;
    position: absolute;
    top: 0;
    left: 0;
}

.companies__section .text_Content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30rem;
    z-index: 10;
}

.companies__section .text_Content h1{
    color: #192A1F;
    font-size: 70px;
    line-height: 0.9em;
    letter-spacing: -0.02em;
}

.companies__section .text_Content p{
    color: #003b159d;
    font-size: 16px;
    line-height: 1.4em;
}

.companies__section .bg-white {
    padding: 0 3rem;
}

@media screen and ( max-width: 600px ){
    .companies__section h1 {
        font-size: 3rem!important;
        text-align: center;
    }
    .companies__section .text_Content {
        width: 100%;
    }
    .companies__section .bg_map {
        height: 40rem;
    }
    .companies__section .bg-white {
        padding: 0.5rem;
    }
    .companies__section .bg-white .overlay{
        height: 35rem;
    }
}

@media screen and ( max-width: 450px ){
    .companies__section h1 {
        padding-top: 2rem;
        line-height: 3.5rem!important;
    }
    .offers__section ul {
        width: 100%!important;
        padding: 0;
    }
    
}