.carousel_slide-social {
    position: absolute;
    width: fit-content;
    height: fit-content;
    top: 40%;
    left: 8rem;
    z-index: 5;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.carousel_slide-scroll {
    position: absolute;
    width: fit-content;
    height: 100vh;
    top: 0;
    right: 8rem;
    z-index: 6;
    gap: 1rem;
    flex-direction: row;
    transform: rotate(90deg);
    align-items: center;
    justify-content: center;
}

.carousel_slide-scroll span.scroll-down-text{
    cursor: pointer;
    color: #7ee3f5;
    line-height: 47px;
    display: block;
    text-transform: uppercase;
}

.carousel_slide-scroll svg {
    transform: rotate(-90deg);
    transition: all 0.3s ease-in-out; 
}

.carousel_slide-scroll:hover svg {
    transform: rotate(-90deg) translateY(160px);
}

.carousel_slide-social span a svg {
    cursor: pointer;
    transform: scale(1);
    transition: all 0.3s ease-in-out;
}

.carousel_slide-social span a:hover svg {
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
}


.carousel_section {
  position: relative;
  top: 0;
  width: 100%;
  margin-top: -5.5rem;
  border-bottom: 1px solid #05b1a8
}

.carousel_section .carousel_slide .carousel_slide-overlay {
    width: 100%;
    height: 100vh;
    position: absolute;
    background-color: rgba(0, 92, 104, 0.1);
    top: 0;
    left: 0;
}

.carousel_section .slick-dots {
    bottom: 10%!important;
    left: 80px!important;
    display: flex!important;
    flex-direction: row!important;
    width: fit-content!important;
}

.carousel_section .slick-dots li{
    padding: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: rgba(255,255,255, 0.4);
}

.carousel_section .slick-dots li.slick-active button {
    min-width: 8px;
    height: 8px;
    background-color: #fff;
}

.carousel_section .slick-dots li button {
    background-color: rgb(0, 43, 40);
    border-radius: 50%;
    min-width: 8px;
    height: 8px;
}

.carousel_section .slick-dots li button:before {
    content: ''!important;
}

.carousel_section .carousel_slide .carousel_slide-img{
    background-position: center center;
    background-size: cover; 
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel_section .carousel_slide .carousel_slide-img .text-content{
    position: relative;
    z-index: 10;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
}

.carousel_section .carousel_slide .carousel_slide-img .text-content div.text-content-upper{
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 1.5rem;

}

.carousel_section .carousel_slide .carousel_slide-img .text-content div.text-content-lower{
    display: flex;
    width: 50%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.carousel_section .carousel_slide .carousel_slide-img .text-content h1{
    color: #E1FFD9;
    font-size: 6rem;
    text-align: start;
    width: 50%;
    font-weight: 600;
    line-height: 1em;
    letter-spacing: -0.05em;
    text-shadow: 0px 0px 0px rgba(0, 77, 4, 0.3);
}

.carousel_section .carousel_slide .carousel_slide-img .text-content p{
    color: #F8FFED9E;
    font-size: 16px;
    line-height: 25px;
    width: 60%;
    text-align: start;
}


.custom-next-arrow .custom-arrow-icon, .custom-prev-arrow .custom-arrow-icon{
    position: relative;
    bottom: 0.3rem;
}

.custom-next-arrow, .custom-prev-arrow {
  padding: 0.5rem 1rem!important;
  border: 1px solid #A4FF9661!important;
  border-radius: 15px!important;
  height: auto!important;
  width: auto!important;
}

.custom-next-arrow:hover, .custom-prev-arrow:hover {
    background-color: #32b1bb!important;
    border-color: #00000000!important;
}

.custom-next-arrow:hover svg, .custom-prev-arrow:hover svg {
    fill: #fff!important;
}

.custom-next-arrow:hover::before{
    width: 75px !important;
    left: 78% !important;
}

.custom-prev-arrow {
    position: absolute;
    left: 78%!important;
    top: 85%;
    transform: translateY(-50%);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #A4FF9661;
    border-radius: 15px;
    padding: 0.5rem 1rem;
    background-color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .custom-prev-arrow:hover {
    background-color: #32b1bb;
    border-color: transparent;
  }
  
  .custom-prev-arrow .custom-arrow-icon {
    position: relative;
    font-size: 20px;
    color: #fff;
    transition: transform 0.3s ease-in-out;
  }
  
  .custom-prev-arrow::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    width: 0;
    height: 2px;
    background-color: #fff;
    transition: width 0.3s ease-in-out;
  }
  
  .custom-prev-arrow:hover::after {
    width: 40px;
    right: 5px;
  }

  .custom-prev-arrow:hover .custom-arrow-icon {
    transform: translateX(-17px); /* Move the chevron to the end of the arrow */
  }

.custom-next-arrow:hover,
.custom-prev-arrow:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.custom-arrow-icon {
  color: #fff;
  font-size: 20px;
}

.slick-prev:before, .slick-next:before{
    content: ''!important;
}

.custom-next-arrow {
    position: absolute;
    right: 12%;
    top: 85%;
    transform: translateY(-50%);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #A4FF9661;
    border-radius: 15px;
    padding: 0.5rem 1rem;
    background-color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .custom-next-arrow:hover {
    background-color: #32b1bb;
    border-color: transparent;
  }
  
  .custom-next-arrow .custom-arrow-icon {
    position: relative;
    font-size: 20px;
    color: #fff;
    transition: transform 0.3s ease-in-out;
  }
  
  .custom-next-arrow::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    width: 0;
    height: 2px;
    background-color: #fff;
    transition: width 0.3s ease-in-out;
  }
  
  .custom-next-arrow:hover::after {
    width: 40px;
    left: 5px;
  }
  
  .custom-next-arrow:hover .custom-arrow-icon {
    transform: translateX(17px); /* Move the chevron to the end of the arrow */
  }

  @media screen and (max-width: 991px ) {
    .carousel_section .carousel_slide .carousel_slide-img .text-content h1{
      font-size: 3rem;
      text-align: center;
      width: 90%;
    }
    
    .carousel_section {
      margin-top: -8.5rem;
    }
    .carousel_section .carousel_slide .carousel_slide-img, .carousel_section .carousel_slide-overlay {
      height: 140vh!important;
    }

    .carousel_section .carousel_slide .carousel_slide-img .text-content div.text-content-lower{
      width: 100%;
      align-items: center;
    }

    .carousel_section .carousel_slide .carousel_slide-img .text-content p{
      width: 80%;
      text-align: center;
    }

    .carousel_section .slick-dots {
        left: 50%!important;
        transform: translateX(-50%)      
    }
    .slick-prev, .slick-next{
      display: none!important;
    }

    .carousel_slide-social {
        transform: translateX(-50%);
        top: 80%;
        left: 50%;
        gap: 2rem;
        flex-direction: row;
    }
  }

  @media screen and (max-width: 600px ) {
    
    .carousel_section .carousel_slide .carousel_slide-img, .carousel_section .carousel_slide-overlay {
      height: 100vh!important;
    }
    
    .carousel_section .carousel_slide .carousel_slide-img, .carousel_section{
      height: 100vh!important;
    }
  }

