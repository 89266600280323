.projects__Section {
    margin: 4rem 0 0;
}

.projects__Section .slick-dots {
    bottom: 0;
    left: 2%;
    display: flex !important;
    flex-direction: column;
    top: 65%;
    transform: translateY(-65%);
    gap: 0.5rem;
}

.projects__Section .slick-dots li.slick-active button{
    padding: 0!important;
    display: flex!important;
}

.projects__Section .slick-dots li button::before{
    font-size: 8px!important;
    color: rgba(255,255,255,0.8)
}

.projects__Section .slick-dots li button::after{
    content: "";
    position: absolute;
    border: 1px solid transparent;
    height: 100%;
    width: 100%;
    top: -1px;
    left: 0;
    border-radius: 40px;
    transform: scale(0);
}

.projects__Section .slick-dots li.slick-active button::after{
    border-width: 1px;
    border-style: solid;
    border-color: #fff;
    border-left: none;
    border-right: none;
    transform: scale(1);
    animation: spin 1s linear 0s infinite;
}

.projects__Section .up_next-arrow {
    top: 37%;
    left: 33px;
    z-index: 10;
    opacity: 1;
    font-size: 20px;
}

.projects__Section .up_next-arrow svg{
    fill: rgba(255,255,255,0.7);
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
}

.projects__Section .up_next-arrow:hover svg{
    fill: #fff;
    transform: translateY(-5px);
    transition: all 0.3s ease-in-out;
}

.projects__Section .up_down-arrow {
    top: 65%;
    left: 33px;
    z-index: 10;
    opacity: 1;
    font-size: 20px;
}

.projects__Section .up_down-arrow svg{
    fill: rgba(255,255,255,0.7);
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
}

.projects__Section .up_down-arrow:hover svg{
    fill: #fff;
    transform: translateY(5px);
    transition: all 0.3s ease-in-out;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


@media screen and ( min-width: 1440px ){
    
    .projects__Section .up_next-arrow, .projects__Section .up_down-arrow {
        left: 43px;
    }
}