.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to bottom, #1e3c72, #2a5298);
    color: white;
    text-align: center;
  }
  
  .globe-container {
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
  }
  
  .globe {
    width: 100%;
    height: 100%;
  }
  
  .error-code {
    font-size: 5rem;
    font-weight: bold;
    margin: 0;
  }
  
  .error-message {
    font-size: 1.2rem;
    margin: 10px 0;
  }
  
  .home-link {
    font-size: 1rem;
    padding: 10px 20px;
    background-color: #ff6f61;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .home-link:hover {
    background-color: #ff3d2e;
  }
  