.contactForm-container {
    margin: 40px auto;
    padding: 0 8rem;
    text-align: center;
  }

  .contactForm-container .w_50 {
    width: 50%;
  }
  
  .contactForm-container h1 {
    color: #0a0f4f;
    margin-bottom: 30px;
    font-size: 3.5rem;
    font-weight: 600;
  }

  @media screen and ( max-width: 600px){
    .contactForm-container {
      margin: 2px;
      padding: 0.5rem;
      text-align: center;
    }
    .contactForm-container .w_50 {
      width: 100%;
    }
    .tel_container {
      margin-bottom: 2rem;
    }
  }
  