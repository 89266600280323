/* General styles for the navigation bar */
nav {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    backdrop-filter: blur(16px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-right: 3rem;
    position: relative;
    z-index: 10;
  }
  
  .logo_div {
    padding: 1rem;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    background: radial-gradient(rgba(0,0,0,0.1) 0%, transparent 100%);
    width: 50%;
  }
  
  .hamburger {
    display: none; /* Hidden by default */
    cursor: pointer;
  }
  
  .hamburger-icon {
    color: white;
    font-size: 1.5rem;
  }
  
  .menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .menu ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu-items {
    font-family: "Jost", serif;
    font-weight: 600;
    font-size: 13px;
    position: relative;
    display: flex;
    align-items: center;
    height: 5rem;
  }
  
  .menu-items a {
    color: #fff !important;
    text-decoration: none;
  }
  
  .menu-items > a::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 15px;
    left: 0;
    background-color: #fff;
    transition: width 0.5s ease;
  }
  
  .menu-items a:hover::after {
    width: 100%;
  }

  .has-dropdown {
    position: relative;
  }
  
  .dropdown {
    display: none;
    position: absolute!important;
    top: 100%;
    left: -50%;
    background-color: rgba(0, 0, 0, 0.9);
    border: 1px solid rgba(255, 255, 255, 0.3);
    z-index: 15;
    list-style: none;
    min-width: 200px;
  }
  
  .has-dropdown:hover .dropdown {
    display: flex;
    flex-direction: column!important;
  }
  
  .dropdown-item {
    cursor: pointer;
    padding: 10px;
    font-size: 14px;
    padding: 20px;
  }
  
  .dropdown-item a {
    color: white;
    border: none!important;
    text-decoration: none;
  }
  
  .dropdown-item:hover {
    background-color: rgba(48, 153, 252, 0.3)!important;
  }
  
  
  .dropdown-item a:hover {
    color: #ffa500;
  }
  
  /* Media query for screens with a maximum width of 991px */
  @media (max-width: 991px) {
    .hamburger {
      display: block; /* Show hamburger icon */
      padding: 1rem;
      z-index: 20; /* Ensure it stays above the menu */
    }
  
    .menu {
      display: none; /* Hide the menu by default */
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.9);
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 10;
      transition: transform 0.3s ease-in-out;
      transform: translateY(-100%);
    }
  
    .menu.open {
      display: flex; /* Show menu when open */
      transform: translateY(0);
    }
  
    .menu ul {
      flex-direction: column;
      gap: 0!important;
    }
  
    .menu-items {
      font-size: 1.5rem; /* Increase font size for better visibility */
    }
  }

  
  @media (min-width: 1400px) {
    .logo_div {
      width: 7%;
    }
  }
  
  @media (max-width: 1400px) {
    .logo_div {
      width: 10%;
    }
  }
  
  @media (max-width: 991px) {
    .logo_div {
      width: 15%;
    }
  }
  
  @media (max-width: 600px) {
    .logo_div {
      width: 40%;
    }
  }
