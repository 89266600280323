.news__section {
    padding: 0 3rem;
}
.news_slide .news_slide-img {
    height: 25rem;
    background-position: center center;
    position: relative;
    background-size: cover;
    border-radius: 25px;
}

.news_slide-overlay{
    height: 25rem;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    border-radius: 25px;
}

.news_slide .text-content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    height: 25rem;
    width: 85%;
    border-radius: 25px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.news_slide .btn_news{
    padding-bottom: 0.5rem;
    margin: 0.5rem 0!important;
}

.news_slide .btn_news:hover{
    border-bottom: 1px solid #fff;
    border-radius: 0;
}

.news__section .heading__text h1 {
    color: #192A1F;
    font-size: 70px;
    padding-bottom: 1rem;
    line-height: 0.9em;
    letter-spacing: -0.02em;
    text-align: start;
}

.news__section .heading__text p {
    color: #003B1570;
    font-size: 16px;
    line-height: 1.3em;
    text-align: start;
}

.news__section .heading__text .news__btn {
    color: #003B1570;
    font-size: 16px;
    line-height: 1.3em;
    text-align: start;
}

.news__section .navigation-buttons {
    justify-content: flex-start;
}

@media screen and ( max-width: 1400px ) {
    .heading__text h1{
        font-size: 3rem!important;
        text-align: start;
        margin: 1.5rem 0;
    }
    .heading__text p{
        text-align: start;
    }
}

@media screen and ( max-width: 600px ) {
    .heading__text h1{
        font-size: 3rem!important;
        text-align: center;
        margin-bottom: 1.5rem;
    }
    .heading__text p{
        text-align: center;
    }
    .news__btn {
        padding: 2rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .news__section {
        padding: 0.5rem;
    }
    .news_slide .text-content {
        width: 100%;
        text-align: center;
        align-items: center;
    }
    .news__section .navigation-buttons {
        justify-content: center;
    }
}

@media screen and ( max-width: 450px ) {
    .news_slide .news_slide-img, .news_slide-overlay {
        width: 100%;
    }
    .news_slide .news_slide-img, .news_slide-overlay{
        height: 25rem;
    }
}