.location-box {
    padding: 25px;
    border-radius: 10px;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-weight: 600;
    font-size: 18px;
    transition: all 0.3s ease-in-out;
    min-height: 180px;
  }
  
  .location-icon {
    font-size: 40px;
    color: #007bff;
    margin-bottom: 10px;
    transition: color 0.3s ease-in-out;
  }
  
  .location-box h4 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
  }
  
  .location-box p {
    font-size: 14px;
    color: #666;
  }
  
  .location-box:hover {
    background: #32b1bb;
    color: white;
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 123, 255, 0.2);
    cursor: pointer;
  }
  
  .location-box:hover p {
    color: #f8f9fa;
  }
  
  .location-box:hover .location-icon {
    color: white;
  }
  