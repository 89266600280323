.who-we-serve {
    text-align: center;
    
  }
  
  .section-title {
    font-size: 2.8rem;
    color: #222;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .section-subtitle {
    font-size: 1.3rem;
    color: #555;
    margin-bottom: 40px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .industries-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 25px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .industry-card {
    background: white;
    border-radius: 15px;
    padding: 25px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: left;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border: 2px solid transparent;
  }
  
  .industry-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
    border-color: #007bff;
  }
  
  .icon {
    font-size: 3rem;
    color: #007bff;
    margin-bottom: 15px;
    transition: transform 0.3s ease-in-out;
  }
  
  .industry-card:hover .icon {
    transform: rotate(10deg) scale(1.1);
  }
  
  .industry-card h3 {
    font-size: 1.6rem;
    color: #333;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .industry-card p {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 15px;
  }
  
  .industry-points {
    padding-left: 15px;
  }
  
  .industry-points li {
    font-size: 1rem;
    color: #444;
    margin-bottom: 8px;
    list-style-type: none;
    position: relative;
  }
  
  .industry-points li::before {
    content: '-';
    color: #007bff;
    font-weight: bold;
    margin-right: 5px;
  }
  