.footer li a {
  color: #fff !important;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer li:hover a {
  color: #32b1bb !important;
}

.footer input {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.footer button {
  background-color: #32b1bb;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.footer button:hover {
  background-color: #258f95;
}

.w_25{
  width: 25%;
}

.w_50{
  width: 50%;
}

@media screen and (max-width: 768px) {
  .w_25, .w_50{
    width: 100%;
  }
}