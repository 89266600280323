.button-style {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: max-content;
    font-size: 18px;
    font-weight: 400;
    border-style: solid;
    border-width: 1px;
    border-radius: 50px;
    padding: 10px 25px;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    overflow: hidden;
    position: relative;
}

.button-style::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background: linear-gradient(to right, #32b1bb, #32b1bb); /* Green gradient */
    transition: width 0.3s ease-in-out;
    z-index: 0;
}

.button-style:hover::before {
    width: 100%;
}

.button-style:hover {
    color: white!important;
}

.button-style svg {
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
    z-index: 1; /* Ensure it stays above the background animation */
}

.button-style:hover svg {
    transform: translateX(5px);
}

/************* CARD **********/

.cardFlow-comp {
    border-radius: 15px;
    border: 1px solid rgba(255,255,255,0.1);
    width: 24%;
    background: rgba(255, 255, 255, 0.07);
}

.cardFlow-comp .text-content{
  width: 100%;
}

.flowCardSection .cardFlow-comp h1{
  color: #E9FFD3;
  font-size: 22px;
  font-weight: 500;
  line-height: 1em;
  letter-spacing: -0.4px;
}

.flowCardSection .cardFlow-comp p{
  color: #DBFFDBA8;
  font-size: 16px;
  line-height: 1.4em;
}

.cardFlow-icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background-color: #9EDDA7;
  width: 80px;
  height: 80px;
  border-radius: 100%;
}

/************* CARD FLOW **********/

.card-comp {
    border-radius: 15px;
    border: 1px solid rgba(255,255,255,0.1);
    width: 30%;
    margin-top: 5rem;
    background: rgba(255, 255, 255, 0.07);
}

@media screen and ( max-width: 600px ) {
  .card-comp {
    width: 100%;
    margin: 5rem 0;
    top: 0!important;
  }
}

.card-comp img{
    transform: translateY(0) rotateZ(0) scale(1);
    transition: all 0.2s linear;
}

.card-comp img:hover{
    transform: translateY(-20px) rotateZ(-3deg) scale(1.05);
    transition: all 0.5s linear;
}

.serviceCardSection .card-comp h1{
    color: #E9FFD3;
    font-size: 22px;
    font-weight: 500;
    line-height: 1em;
}

.card-comp h5{
    justify-content: start;
    align-items: start;
    color: #E9FFD3A8;
    text-transform: uppercase;
    line-height: 1.3em;
}

.card-comp .count_number{
    color: #E1FFD9;
    font-family: "Bebas Neue", Sans-serif;
    font-size: 55px;
}

.card-comp p{
    color: #DBFFDBA8;
    font-size: 16px;
    line-height: 1.4em; 
}

.card-comp .divider{
    border-style: solid;
    color: #D6FFD51F;
    border-width: 1px;
}


/********* ACCORDION ***********/
.accordion {
    border: 1px solid #0000007D;
    border-bottom: none;
    width: 100%;
  }
  
  .accordion-item {
    border-bottom: 1px solid #0000007D;
    border-bottom: none;
  }

  .accordion__title {
    font-size: 20px;
    font-weight: 500;
  }

  .accordion-header {
    padding: 2rem;
    cursor: pointer;
    border-radius: 0;
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  

  .accordion-header-tech {
    padding: 1rem;
    cursor: pointer;
    border-radius: 0;
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .accordion-icon-container {
    position: relative;
    display: inline-block;
    width: 1.5rem; /* Adjust as per your icon size */
    height: 1.5rem;
  }

  .accordion-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .accordion-icon.icon-active {
    opacity: 1;
    transform: scale(1);
  }
  
  .accordion-icon.icon-hidden {
    opacity: 0;
    transform: scale(0.8);
  }
  
  .accordion-content {
    max-height: 0;
    overflow: hidden;
    border-bottom: 1px solid #0000007D;
    text-align: start;
    transition: max-height 0.3s ease;
  }
  
  .accordion-content-tech {
    max-height: 0;
    overflow: hidden;
    text-align: start;
    transition: max-height 0.3s ease;
  }
  
  .accordion-content.active {
    max-height: 400px;
    transition: max-height 0.3s ease;
    padding: 10px;
  }

  .accordion-content-tech.active {
    max-height: 400px;
    transition: max-height 0.3s ease;
    padding: 10px;
  }
  

  /*********** HEADING **********/
  .projectContent h1{
    color: #192A1F;
    font-size: 70px;
    line-height: 0.9em;
    letter-spacing: -0.02em;
  }
  
  .projectContent p{
    color: #003B1570;
    line-height: 1.4em;
  }

  
  .heading__text svg {
    align-items: flex-start;
  }
  
  @media screen and ( max-width: 600px ){
  .projectContent h1{
    font-size: 3rem;
  }

  .heading__text svg {
    align-items: center;
  }
    
  .cardFlow-comp {
    margin: 2rem 0;
    width: 100%;
    top: 0!important;
  }

  
  .cardFlow-comp .text-content{
    width: 100%!important;
    margin-top: 1rem;
  }
}