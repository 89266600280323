.TimelineBg__section {
  position: relative;
  width: 100%;
  height: 140vh;
  overflow: hidden;
}

.TimelineBg__section video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(1) contrast(1) saturate(1) hue-rotate(61.2deg);
}

.TimelineBg__content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2rem;
  padding: 40px;
  height: 100vh;
  position: absolute;
  border: 1px solid rgba(255,255,255,0.3);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 100%;
  max-width: 80%;
  text-align: center;
  background: rgba(255,255,255,0.1); 
  color: white; 
  border-radius: 10px; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); 
}

.slick-timeline-container {
  width: 100%;
  height: 60vh;
  padding: 20px 0;
}

.slick-timeline-container .text-content {
  padding: 1rem;
}

/* Customize dots */
.slick-timeline-dots {
  bottom: -40px;
}

.slick-timeline-dots li button:before {
  color: rgba(255, 255, 255, 0.5);
}

.slick-timeline-dots li.slick-timeline-active button:before {
  color: white;
}

/* Customize arrows */
.slick-timeline-prev,
.slick-timeline-next {
  z-index: 1;
  width: 40px;
  height: 40px;
}

.slick-timeline-prev {
  left: 25px;
}

.slick-timeline-next {
  right: 25px;
}

.slick-timeline-prev:before,
.slick-timeline-next:before {
  font-size: 40px;
  opacity: 0.75;
}

.timeline-line {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 0.1rem;
  background: #fff;
  width: 100%;
}

.timeline-element {
  position: relative;
  width: 100%;
}

.top-element {
  margin-bottom: 2.5rem;
}

.bottom-element {
  margin-top: 2.5rem;
}

@media screen and (max-width: 600px) {
  .slick-timeline-prev {
    left: 10px;
  }

  .slick-timeline-next {
    right: 10px;
  }

  .TimelineBg__content{
    padding: 40px 0;
  }
  
}
