.about-us-container {
    padding: 3rem 1rem;
  }
  
  
  .tab-buttons {
    display: flex;
    gap: 2rem;
    justify-content: flex-start;
  }
  
  .tab-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    position: relative;
    padding: 0;
    margin-bottom: 1rem;
  }
  
  .tab-button::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #000;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
  
  .tab-button.active-tab::after {
    transform: scaleX(1);
  }
  
  .tab-content {
    text-align: start;
  }

@media screen and (max-width: 600px) {
  .about__container {
    padding: 0 0.5rem;
    margin: 0.5rem 0;
  }

  .about-us-container {
    padding: 0!important;
  }
  

  .tab-buttons {
    flex-direction: column;
    gap: 0.5rem;
    align-items: stretch;
  }

  .tab-button {
    font-size: 18px;
    width: 100%;
    text-align: left;
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .tab-button::after {
    display: none;
  }

  .tab-content {
    font-size: 16px;
    line-height: 1.6rem;
    padding: 15px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }

  .tab-content.active {
    max-height: 1000px;
    border-bottom: 1px solid #dee2e6;
  }
}

/* Mobile Accordion Styles */
.accordion-item {
  margin-bottom: 1rem;
}

.accordion-button {
  width: 100%;
  text-align: left;
  padding: 15px 20px;
  width: 100%;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.accordion-button:hover {
  background-color: #e9ecef;
}

.accordion-button.active {
  background-color: #e9ecef;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.toggle-icon {
  font-size: 24px;
  font-weight: bold;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.accordion-button.active .toggle-icon {
  transform: rotate(180deg);
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background-color: white;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

.accordion-content.active {
  max-height: 1000px;
  border-bottom: 1px solid #dee2e6;
}

.accordion-content p {
  margin: 0;
  padding: 15px 20px;
}